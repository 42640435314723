import React from "react"

import CursorProvider from "context/cursor"

import Layout from "components/Layout"
import {
  Hero,
  Discover,
  Entertainment,
  Residence,
  Baner,
  Gallery,
  Location,
  History,
  About,
  Contact,
  CallToAction,
  Nav,
} from "page_components/hugo-landing"

import { Apartments } from "page_components/investment"

const Investment = ({ location }) => {
  const investment = "Browar Hugo Scobel"
  const investmentRaw = "hugo_scobel"

  return (
    <CursorProvider variant="hugo">
      <Layout
        location={location}
        rmGlobalPopup
        mainClass="hugo-landing"
        seo={{
          title: investment,
        }}
      >
        <Nav />
        <Hero />
        <Discover />
        <Apartments investment={investment} investmentRaw={investmentRaw} />
        <Entertainment />
        <Residence />
        <Baner />
        <Gallery />
        <Location />
        <History />
        <About />
        <Contact />
        <CallToAction />
      </Layout>
    </CursorProvider>
  )
}

export default Investment
